import React, { useEffect, useState } from "react";
import Axios from "axios";
import "../Analyzer/style.scss";
import Button from "react-bootstrap/Button";
import SelectMunicipalityModal from "./Modals/SelectMunicipalityModal";
import TipoAnalizadores, {
  TipoAnalizadoresSelect,
} from "../../utils/Constants/TipoAnalizadores";

export default function HomeAnalyzer(props) {
  const [showSelectMunicipalityModal, setShowSelectMunicipalityModal] =
    useState(false);
  const [municipalities, setMunicipalities] = useState([]);
  const { checkLoggedIn, history, userData } = props;
  const [municipalitySelected, setMunicipalitySelected] = useState(-1);
  const [tipoEquipoSelected, setTipoEquipoSelected] = useState(-1);
  const [tipoEquipos, setTipoEquipos] = useState(-1);

  const closeModal = () => {
    setShowSelectMunicipalityModal(false);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    switch (tipoEquipoSelected) {
      case TipoAnalizadores.VIDEO_SEMAFORO.id:
        history.push(`/analyzer/${municipalitySelected}/cropper`);
        break;
      case TipoAnalizadores.CINEMOMETRO.id:
        //history.push(`/trucam/analyzer/${municipalitySelected}`);
        break;
      case TipoAnalizadores.ESTACIONAMIENTO.id:
        history.push(`/trues/analyzer/${municipalitySelected}/e`);
        break;
      case TipoAnalizadores.TRUCAM.id:
        history.push(`/trues/analyzer/${municipalitySelected}/t`);
        break;
      case TipoAnalizadores.PROTONICKEL.id:
        history.push(`/trues/analyzer/${municipalitySelected}/p`);
        break;
      default:
        break;
    }
  };

  const getMunicipalities = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get("/api/municipality/filter", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setMunicipalities(response.data.municipalities);
    });
  };

  useEffect(() => {
    getMunicipalities();
    configurarEquiposAnalizar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const configurarEquiposAnalizar = () => {
    let tipoAnalizador = [];
    if (userData.canAnalyzeSemaphore == 1) {
      tipoAnalizador.push(
        TipoAnalizadoresSelect.find(
          (x) => x.id == TipoAnalizadores.VIDEO_SEMAFORO.id
        )
      );
    }
    if (userData.canAnalyzeCinemometer == 1) {
      tipoAnalizador.push(
        TipoAnalizadoresSelect.find(
          (x) => x.id == TipoAnalizadores.CINEMOMETRO.id
        )
      );
    }
    if (userData.canAnalyzeParking == 1) {
      tipoAnalizador.push(
        TipoAnalizadoresSelect.find(
          (x) => x.id == TipoAnalizadores.ESTACIONAMIENTO.id
        )
      );
    }

    if (userData.canAnalyzeTrucam == 1) {
      tipoAnalizador.push(
        TipoAnalizadoresSelect.find((x) => x.id == TipoAnalizadores.TRUCAM.id)
      );
    }

    if (userData.canAnalyzeProtonickel == 1) {
      tipoAnalizador.push(
        TipoAnalizadoresSelect.find(
          (x) => x.id == TipoAnalizadores.PROTONICKEL.id
        )
      );
    }

    if (tipoAnalizador.length > 0) {
      setTipoEquipos(tipoAnalizador);
      setTipoEquipoSelected(tipoAnalizador[0].id);
    } else {
      alert(
        "Atención",
        "Usted no tiene ningun tipo de equipo asignado para analizar. Ponganse en contacto con su administrador"
      );
    }
  };

  return (
    <div className="analyzerhome-container">
      <div className="home-wrapper">
        <img
          src={require("../../../assets/images/analyzer/home.png")}
          alt="car"
        />
        <div className="bienvenido">
          <h1> ¡Bienvenido!</h1>
          <h4>
            Empiece a analizar una presunta infracción haciendo click en el
            botón de abajo
          </h4>
          <Button
            className="btn-celeste"
            onClick={() => setShowSelectMunicipalityModal(true)}
          >
            ANALIZAR
          </Button>
        </div>
      </div>
      <SelectMunicipalityModal
        show={showSelectMunicipalityModal}
        onHide={closeModal}
        setTipoEquipoSelected={setTipoEquipoSelected}
        tipoEquipos={tipoEquipos}
        setMunicipalitySelected={setMunicipalitySelected}
        handleSubmit={handleSubmit}
        question={"¿Qué municipio desea editar?"}
        municipalities={municipalities}
      ></SelectMunicipalityModal>
    </div>
  );
}
