import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./Users.scss";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import { SuccessModal } from "../../utils/Modals/SuccessModal";
import { AreYouSureModal } from "../../utils/Modals/AreYouSureModal";
import Loader from "../../utils/Loader/Loader";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Roles, { RolesSelect } from "../../utils/Constants/Roles";
import TipoAnalizadores, {
  TipoAnalizadoresSelect,
} from "../../utils/Constants/TipoAnalizadores";

export default function EditUser(props) {
  const [error, setError] = useState([]);
  const [validated, setValidated] = useState(false);
  const [userData, setUserData] = useState({
    id: "",
    name: "",
    lastname: "",
    phone: "",
    email: "",
    password: null,
    confirmPassword: null,
    address: "",
    city: "",
    roleId: 0,
    CBU: "",
    CUIL: "",
    CP: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showAreYouSureModal, setAreYouSureModal] = useState(false);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const { checkLoggedIn, history } = props;
  const [tipoAnalizadorSelected, setTipoAnalizadorSelected] = useState([]);

  useEffect(() => {
    const id = props.match.params.userId;
    const getUser = async () => {
      const authToken = await checkLoggedIn();
      if (!authToken) {
        return history.push("/");
      }
      await Axios.get(`/api/user/${id}`, {
        headers: { "auth-token": authToken },
      }).then((response) => {
        if (response.data.user) {
          setUserData({
            id: response.data.user.id,
            email: response.data.user.email,
            name: response.data.user.name,
            lastname: response.data.user.lastname,
            address: response.data.user.address,
            city: response.data.user.city,
            roleId: response.data.user.roleId,
            phone: response.data.user.phone,
            CBU: response.data.user.CBU,
            CUIL: response.data.user.CUIL,
            CP: response.data.user.CP,
          });

          let tipoAnalizador = [];
          if (response.data.user.canAnalyzeParking == 1) {
            tipoAnalizador.push(
              TipoAnalizadoresSelect.find(
                (x) => x.id == TipoAnalizadores.ESTACIONAMIENTO.id
              )
            );
          }
          if (response.data.user.canAnalyzeSemaphore == 1) {
            tipoAnalizador.push(
              TipoAnalizadoresSelect.find(
                (x) => x.id == TipoAnalizadores.VIDEO_SEMAFORO.id
              )
            );
          }
          if (response.data.user.canAnalyzeCinemometer == 1) {
            tipoAnalizador.push(
              TipoAnalizadoresSelect.find(
                (x) => x.id == TipoAnalizadores.CINEMOMETRO.id
              )
            );
          }
          if (response.data.user.canAnalyzeTrucam == 1) {
            tipoAnalizador.push(
              TipoAnalizadoresSelect.find(
                (x) => x.id == TipoAnalizadores.TRUCAM.id
              )
            );
          }

          if (response.data.user.canAnalyzeProtonickel == 1) {
            tipoAnalizador.push(
              TipoAnalizadoresSelect.find(
                (x) => x.id == TipoAnalizadores.PROTONICKEL.id
              )
            );
          }

          setTipoAnalizadorSelected(tipoAnalizador);

          setIsLoading(false);
        }
      });
    };
    setIsLoading(true);
    getUser();
  }, [checkLoggedIn, history, props.match.params.userId]);

  const isValid = () => {
    var isValid = true;
    var errors = [];

    if (userData.passsword && userData.password.length < 5) {
      isValid = false;
      errors.push("La contraseña debe tener como mínimo 5 caracteres.");
    }

    if (!userData.password) userData.password = null;
    if (!userData.confirmPassword) userData.confirmPassword = null;

    if (userData.password !== userData.confirmPassword) {
      isValid = false;
      errors.push("Contraseña y confirmar contraseña no coinciden.");
    }

    if (
      userData.phone > 0 &&
      (userData.phone.length < 8 || userData.phone.length > 13)
    ) {
      isValid = false;
      errors.push("El teléfono debe tener entre 8 y 13 dígitos.");
    }

    if (userData.CBU > 0 && userData.CBU.length !== 22) {
      errors.push("El CBU debe tener 22 dígitos.");
      isValid = false;
    }

    if (
      userData.CUIL > 0 &&
      (userData.CUIL.length < 10 || userData.CUIL.length > 12)
    ) {
      errors.push("El CUIL debe tener entre 10 y 12 dígitos.");
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  const handleSubmit = (event) => {
    setError([]);
    setValidated(false);
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      if (
        userData.roleId == Roles.ANALIZADOR.id &&
        tipoAnalizadorSelected.length == 0
      ) {
        alert(
          "Por favor, seleccione que tipo de equipos va a analizar esta persona"
        );
      }
      return;
    }

    if (!isValid()) {
      return;
    }

    if (userData.CUIL === "") {
      setUserData({ ...userData, CUIL: 0 });
    }

    setUserData({
      ...userData,
      tipoAnalizadorId: tipoAnalizadorSelected.map((x) => x.id),
    });

    setValidated(true);

    setAreYouSureModal(true);
  };

  const closeModal = () => {
    setAreYouSureModal(false);
    if (setConfirmationModal) {
      setConfirmationModal(false);
      history.push("/users");
    }
  };

  const handleConfirmation = () => {
    setAreYouSureModal(false);
    setIsLoading(true);

    const updateAccount = async () => {
      const authToken = await checkLoggedIn();
      if (!authToken) {
        return history.push("/");
      }
      await Axios.put("/api/user/", userData, {
        headers: { "auth-token": authToken },
      })
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            setConfirmationModal(true);
          }
        })
        .catch((err) => {
          setError(["Ha ocurrido un error."]);
          setIsLoading(false);
        });
    };
    updateAccount();
  };

  const handleChangeTipoAnalizador = (event) => {
    const {
      target: { value },
    } = event;
    setTipoAnalizadorSelected(value);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleCancel = () => {
    history.push("/users");
  };

  return (
    <div>
      <Loader show={isLoading} />
      <div className="account-container">
        <div className="accountEdit1-wrapper">
          <div className="perfil">
            <div className="header-edit">
              <h1>Editá este usuario</h1>
              <h2>
                {" "}
                Si no escribe nada en el campo contraseña, la misma no se
                modificará
              </h2>
            </div>
            <div className="form-register">
              <Form
                noValidate
                validated={validated}
                id="form"
                onSubmit={handleSubmit}
              >
                <Form.Row>
                  <Form.Group as={Col} md="3" controlId="formGridName">
                    <Form.Label>Nombre* </Form.Label>
                    <Form.Control
                      name="name"
                      type="text"
                      required
                      value={userData.name}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="3" controlId="formGridLastname">
                    <Form.Label>Apellido*</Form.Label>
                    <Form.Control
                      name="lastname"
                      type="text"
                      required
                      value={userData.lastname}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="formGridEmail">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      name="email"
                      value={userData.email}
                      type="email"
                      required
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="3" controlId="formGridPassword">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                      name="password"
                      type="password"
                      value={userData.password}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>Confirmar Contraseña</Form.Label>
                    <Form.Control
                      name="confirmPassword"
                      value={userData.confirmPassword}
                      type="password"
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="formGridRol">
                    <Form.Label>Rol*</Form.Label>
                    <Form.Control
                      required
                      name="roleId"
                      as="select"
                      value={userData.roleId}
                      disabled={
                        props.userData.roleId ===
                        Roles.SUPERVISOR_ANALIZADORES.id
                      }
                      custom
                      onChange={handleChange}
                    >
                      <option hidden value="">
                        Selecciona...
                      </option>
                      {RolesSelect.map((rol, i) => {
                        return (
                          <option value={rol.id}>{rol.descripcion}</option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  {userData.roleId == Roles.ANALIZADOR.id && (
                    <Form.Group
                      as={Col}
                      className="align-self-end"
                      md="3"
                      controlId="formGridTipoAnalizador"
                    >
                      <FormControl
                        className="form-control-select"
                        variant="standard"
                      >
                        <InputLabel id="demo-tipoequipo">
                          Tipo de equipo a analizar *
                        </InputLabel>

                        <Select
                          labelId="demo-tipoequipo"
                          name="tipoAnalizadorId"
                          multiple
                          required={userData.roleId == Roles.ANALIZADOR.id}
                          value={tipoAnalizadorSelected}
                          onChange={handleChangeTipoAnalizador}
                          input={
                            <OutlinedInput label="Tipo de equipo a analizar" />
                          }
                          renderValue={(selected) =>
                            selected.map((x) => x?.descripcion).join(", ")
                          }
                        >
                          {TipoAnalizadoresSelect.map((rol, i) => {
                            return (
                              <MenuItem key={rol.id} value={rol}>
                                <Checkbox
                                  checked={
                                    tipoAnalizadorSelected.indexOf(rol) > -1
                                  }
                                />
                                <ListItemText primary={rol.descripcion} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Form.Group>
                  )}
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="3" controlId="formGridPhone">
                    <Form.Label>Teléfono </Form.Label>
                    <Form.Control
                      value={userData.phone}
                      name="phone"
                      type="number"
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>CBU </Form.Label>
                    <Form.Control
                      value={userData.CBU}
                      name="CBU"
                      type="number"
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="3">
                    <Form.Label>CUIL </Form.Label>
                    <Form.Control
                      value={userData.CUIL}
                      name="CUIL"
                      type="number"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group controlId="formGridAddress1" as={Col} md="3">
                    <Form.Label>Dirección</Form.Label>
                    <Form.Control
                      name="address"
                      value={userData.address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>Código Postal</Form.Label>
                    <Form.Control
                      name="CP"
                      value={userData.CP}
                      type="number"
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="formGridCity">
                    <Form.Label>Ciudad</Form.Label>
                    <Form.Control
                      name="city"
                      value={userData.city}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form.Row>
                {error.map((errorDescription, i) => {
                  return (
                    <Form.Row key={i}>
                      <span className="error">{errorDescription}</span>
                    </Form.Row>
                  );
                })}

                <Form.Row style={{ justifyContent: "flex-start" }}>
                  <Button
                    form="form"
                    className="btn-gris"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>

                  <Button form="form" className="btn-celeste" type="submit">
                    Editar
                  </Button>
                </Form.Row>
              </Form>
            </div>
          </div>
        </div>
        <AreYouSureModal
          show={showAreYouSureModal}
          handleConfirmation={handleConfirmation}
          onHide={closeModal}
          text={"¿Está seguro que desea editar su perfil?"}
        />
        <SuccessModal
          show={showConfirmationModal}
          onHide={closeModal}
          text={"Se ha editado con éxito el perfil"}
        ></SuccessModal>
      </div>
    </div>
  );
}
